import React from "react"

import Layout from "../components/layout"

import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
      <div style={{
        minHeight: '200px'
      }}>
        <h3
          style={{
            textAlign: `center`,
            lineHeight: '200px'
          }}
        >SITO IN COSTRUZIONE</h3>
      </div>
  </Layout>
)

export default IndexPage
